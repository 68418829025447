import { useTranslation } from 'react-i18next';
import React from 'react';

import Acknowledgment from '../../components/Acknowledgment';

const AcknowledgmentMobilePage = () => {
  const { t } = useTranslation();

  return (
    <section className="container mx-auto px-8 lg:flex">
      <Acknowledgment />
    </section>
  );
};

export default AcknowledgmentMobilePage;
